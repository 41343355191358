import { SEGMENT_FORM_FIELDS } from "@/constants";

export const findFieldByName = (name: string) => {
  for (const section of SEGMENT_FORM_FIELDS) {
    const field = section.fields.find((field) => field.name === name);
    if (field) {
      return { ...field, section: section.section };
    }
  }
  return null;
};
