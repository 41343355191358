import { Button } from "@/components/ui/button";
import { SEGMENT_FORM_FIELDS } from "@/constants";
import { MouseEvent } from "react";

interface FieldListProps {
  onClick: (fieldName: string) => void;
}

export const FieldList = ({ onClick }: FieldListProps) => {
  return (
    <div className="flex flex-col items-start">
      {SEGMENT_FORM_FIELDS.map((section) =>
        section.fields.map((sectionField) => (
          <Button
            variant={"link"}
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              onClick(sectionField.name);
            }}
            key={sectionField.label}
          >
            {
              <div className="flex items-center gap-1">
                {sectionField.icon}
                <p className="text-webmetic_dark-400">{sectionField.label}</p>
              </div>
            }
          </Button>
        )),
      )}
    </div>
  );
};
