import { QueryGroup } from "@/types";
import { findFieldByName } from "../../companies-filter/utils";

export const getFiltersListString = (filters?: QueryGroup[]) =>
  (filters || []).map((group) =>
    group
      .filter((filter) => filter.value)
      .map(
        (filter) =>
          `"${findFieldByName(filter.field)?.label} ${filter.condition} ${filter.value}"`,
      )
      .join(", "),
  );
