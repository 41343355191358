import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { useEffect, useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import { format, isSameDay, parseISO } from "date-fns";
import useUser from "@/hooks/useUser.ts";
import { useGetSegmentsListQuery, useSegmentMutations } from "@/hooks/index.ts";
import {
  CompaniesFilterDate,
  companiesFilterQueryOptions,
} from "@/routes/companies/companies-filter/companies-filter.loader";
import { SEGMENTS_LIST } from "@/constants";
import { getDateRange, toValidRangeDate } from "@/utils";

export const useCompaniesFilterView = () => {
  const user = useUser();
  const params = useParams();
  const navigate = useNavigate();

  const { data: periodList } = useSuspenseQuery(
    companiesFilterQueryOptions(params.domain!),
  );
  const queryClient = useQueryClient();

  const { data: segmentsList } = useGetSegmentsListQuery({
    domain: params.domain || "",
  });

  const { deleteSegmentMutation } = useSegmentMutations();

  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: toValidRangeDate(searchParams.get("from_date")) || undefined,
    to: toValidRangeDate(searchParams.get("to_date")) || undefined,
  });

  const handleDeleteSegment = (id: string) => {
    deleteSegmentMutation(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [SEGMENTS_LIST] });
      },
    });
  };

  const resetSelectedCompany = (domain = params.domain) => {
    const n = new URLSearchParams(searchParams);
    n.delete("company_id");
    n.delete("segment_id");
    n.delete("from_date");
    n.delete("to_date");

    navigate({
      pathname: `/dashboard/${domain}/companies`,
      search: n.toString(),
    });
  };

  const handleSelectDateRangeOption = (item: CompaniesFilterDate) => () => {
    const range = getDateRange(item);

    updateSearchParamsDateRange(range);
  };

  const updateSearchParamsDateRange = (dr?: DateRange) => {
    resetSelectedCompany();
    setSearchParams((p) => {
      const n = new URLSearchParams(p);
      if (n.has("segment_id")) {
        n.delete("segment_id");
      }

      if (dr?.from) n.set("from_date", format(dr.from, "yyyy-MM-dd"));
      else n.delete("from_date");
      if (dr?.to) n.set("to_date", format(dr.to, "yyyy-MM-dd"));
      else n.delete("to_date");
      return n;
    });
  };

  const handleUpdateSearchParams = () => updateSearchParamsDateRange(dateRange);

  useEffect(() => {
    setDateRange({
      from: toValidRangeDate(searchParams.get("from_date")) || undefined,
      to: toValidRangeDate(searchParams.get("to_date")) || undefined,
    });
  }, [searchParams]);

  const selectedItem = useMemo(() => {
    const f = toValidRangeDate(searchParams.get("from_date"));
    const t = toValidRangeDate(searchParams.get("to_date"));
    if (!f || !t) return null;
    for (const item of periodList) {
      const dateStart = parseISO(item.date_start);
      const dateEnd = parseISO(item.date_end);
      if (isSameDay(dateStart, f) && isSameDay(dateEnd, t)) {
        return item;
      }
    }
    return null;
  }, [searchParams, periodList]);

  return {
    user,
    periodList,
    params,
    segmentsList,
    dateRange,
    selectedItem,
    setDateRange,
    resetSelectedCompany,
    handleDeleteSegment,
    handleSelectDateRangeOption,
    handleUpdateSearchParams,
  };
};
