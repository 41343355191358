import { useSegmentMutations } from "@/hooks";
import { SEGMENTS_LIST } from "@/constants";
import { useQueryClient } from "@tanstack/react-query";
import { Segment } from "@/types/segment";

export const useSegmentManagement = () => {
  const queryClient = useQueryClient();
  const { createSegmentMutation, updateSegmentMutation } =
    useSegmentMutations();

  const updateSegmentList = () => {
    queryClient.invalidateQueries({ queryKey: [SEGMENTS_LIST] });
  };

  const handleSaveSegment = async (
    values: any,
    isEditMode: boolean,
    segmentToEdit: Segment | null,
  ) => {
    if (isEditMode && segmentToEdit) {
      return updateSegmentMutation(
        { id: segmentToEdit.id, data: values },
        { onSuccess: updateSegmentList },
      );
    }
    return createSegmentMutation(values, {
      onSuccess: updateSegmentList,
    });
  };

  return { handleSaveSegment };
};
