import { ChangeEvent, useState } from "react";

type SearchInputProps = {
  isSegmentSearch: boolean;
  categoryList: { value: string; label: string }[];
  searchValue: string;
  onChangeSearch: (val: string) => void;
  dropDownValue?: string;
  onChangeDropdownValue: (val: string) => void;
  wrapClasses?: string;
};

export const SearchInput = ({
  isSegmentSearch,
  categoryList,
  onChangeSearch,
  searchValue,
  dropDownValue,
  onChangeDropdownValue,
  wrapClasses,
}: SearchInputProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(e.target.value);
  };

  const onSelectDropdown = (val: string) => {
    onChangeDropdownValue(val);
    setIsOpen(false);
  };
  const toggleDropdown = () => {
    if (isSegmentSearch) return;

    setIsOpen(!isOpen);
  };

  const getLabelByValue = (value?: string) => {
    const item = categoryList.find((entry) => entry.value === value);
    return item ? item.label : "All categories";
  };

  return (
    <div className={`${wrapClasses}`}>
      <div className="flex">
        <label
          htmlFor="search-dropdown"
          className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Your Email
        </label>

        <button
          id="dropdown-button"
          data-dropdown-toggle="dropdown"
          className="z-10 flex w-[145px] flex-shrink-0 items-center justify-between rounded-s-lg border border-gray-300 bg-gray-100 px-4 py-2.5 text-center text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-700"
          type="button"
          onClick={toggleDropdown}
          disabled={isSegmentSearch}
        >
          {isSegmentSearch ? "Segmentsuche" : getLabelByValue(dropDownValue)}

          <svg
            className="ms-2.5 h-2.5 w-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>

        {isOpen ? (
          <div
            id="dropdown"
            className={`absolute top-[50px] z-10 w-44 divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700`}
          >
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdown-button"
            >
              {categoryList.map(({ value, label }) => {
                return (
                  <li>
                    <button
                      type="button"
                      className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => onSelectDropdown(value)}
                    >
                      {label}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
        <div className="relative w-full">
          <input
            type="search"
            id="search-dropdown"
            className="block h-full w-full rounded-r-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
            placeholder="Suche..."
            value={searchValue}
            onChange={onSearch}
          />
          <button
            type="submit"
            className="absolute end-0 top-0 h-full rounded-e-lg border border-primary bg-webmetic_dark p-2.5 text-sm font-medium text-white transition duration-300 ease-in-out hover:bg-webmetic_light hover:text-gray-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-webmetic_light dark:hover:bg-webmetic_dark dark:focus:ring-primary-800"
          >
            <svg
              className="h-4 w-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            <span className="sr-only">Suche</span>
          </button>
        </div>
      </div>
    </div>
  );
};
