import { apiHandlers, SegmentListParams } from "@/api";
import { SEGMENTS_LIST } from "@/constants";
import { Segment } from "@/types/segment";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export const useGetSegmentsListQuery = (
  params: SegmentListParams,
): UseQueryResult<Segment[], Error> => {
  return useQuery<Segment[], Error>({
    queryKey: [SEGMENTS_LIST, params.domain],
    queryFn: async () => {
      const response = await apiHandlers.segment.getSegments(params);
      return response.data;
    },
    enabled: !!params,
  });
};
