import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import validator from "validator";
import PasswordResetSuccessModal from "../modal/PasswordResetSuccessModal";
import analytics from "../../analytics";
import LogoWhite from "../../assets/ci/webmetic-logo-white.svg";
import LogoBlack from "../../assets/ci/webmetic-logo-black.svg";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const [loadingView, setLoadingView] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setEmailError("");
    setGeneralError("");
    setSuccessMessage("");

    if (!email) {
      setEmailError("Bitte geben Sie Ihre E-Mail-Adresse ein.");
      return;
    }

    if (!validator.isEmail(email)) {
      setEmailError("Bitte geben Sie eine gültige E-Mail-Adresse ein!");
      return;
    }

    setLoadingView(true);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API}/api/auth/user_password_reset`,
        { email: email },
      );
      setLoadingView(false);
      if (response.data.code === 200) {
        setShowSuccessModal(true);
        analytics.track("password_reset");
      } else {
        handleErrorResponse(response.data);
        analytics.track("error", {
          error: "password_reset_error",
          error_type: "api_error",
          error_message: response.data.message,
        });
      }
    } catch (error) {
      setLoadingView(false);
      console.error("Error during password reset request:", error);
      if (error.response && error.response.data) {
        handleErrorResponse(error.response.data);
        analytics.track("error", {
          error: "password_reset_error",
          error_type: "api_error",
          error_message: error.response.data.message,
        });
      } else {
        setGeneralError(
          "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
        );
        analytics.track("error", {
          error: "password_reset_error",
          error_type: "network_error",
          error_message: error.message || "Unknown network error",
        });
      }
    }
  };

  const handleErrorResponse = (errorData) => {
    const { code, message } = errorData;
    switch (code) {
      case 404:
        setEmailError("Kein Konto mit dieser E-Mail-Adresse gefunden.");
        analytics.track("error", {
          error: "password_reset_error",
          error_type: "account_not_found",
          error_message: "No account found with this email address",
        });
        break;
      case 500:
        setGeneralError(
          `Ein Serverfehler ist aufgetreten. Bitte kontaktieren Sie uns (Fehlercode: #${code})`,
        );
        analytics.track("error", {
          error: "password_reset_error",
          error_type: "server_error",
          error_message: "Server error occurred",
        });
        break;
      default:
        setGeneralError(
          message ||
            "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
        );
        analytics.track("error", {
          error: "password_reset_error",
          error_type: "unexpected_error",
          error_message: message || "An unexpected error occurred",
        });
    }
  };
  const buttonLinkLogin = () => {
    navigate("/login");
  };
  return (
    <section className="w-full bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0">
        <div className="mb-6 flex items-center">
          <Link to="/" className="flex items-center">
            <img
              src={LogoBlack}
              className="mr-3 h-6 dark:hidden sm:h-9"
              alt="Webmetic Logo Light"
            />
            <img
              src={LogoWhite}
              className="mr-3 hidden h-6 dark:block sm:h-9"
              alt="Webmetic Logo Dark"
            />
          </Link>
        </div>
        <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0">
          <div className="p-6 sm:p-8">
            <h1 className="pb-4 text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
              Passwort zurücksetzen
            </h1>
            <form onSubmit={handleResetPassword}>
              <div>
                <label htmlFor="email" className="form-label">
                  Ihre E-Mail
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className={`form-input transition-all duration-200 ${
                      emailError
                        ? "form-input-error"
                        : isEmailValid
                          ? "form-input-success"
                          : ""
                    }`}
                    placeholder="name@firma.de"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setIsEmailValid(validator.isEmail(e.target.value));
                    }}
                  />
                </div>
                <div className="min-h-[20px]">
                  {emailError && (
                    <p className="form-message-error transition-all duration-200">
                      <span className="form-message-highlight">Fehler:</span>{" "}
                      {emailError}
                    </p>
                  )}
                  {isEmailValid && !emailError && (
                    <p className="form-message-success transition-all duration-200">
                      Das sieht nach einer gültigen E-Mail Adresse aus.
                    </p>
                  )}
                </div>
              </div>
              {generalError && (
                <p className="text-sm text-red-500">{generalError}</p>
              )}
              {successMessage && (
                <p className="text-sm text-green-500">{successMessage}</p>
              )}
              <button
                type="submit"
                className="btn mt-4 w-full"
                disabled={loadingView}
              >
                {loadingView ? (
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="me-3 inline h-4 w-4 animate-spin text-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                ) : (
                  "Passwort zurücksetzen"
                )}
              </button>
            </form>

            <button onClick={buttonLinkLogin} className="btn-alt mt-2 w-full">
              Zurück zur Anmeldung
            </button>
          </div>
        </div>
      </div>
      <PasswordResetSuccessModal
        show={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      />
    </section>
  );
};

export default ForgotPassword;
