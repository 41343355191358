import { CompanyMode } from "@/types";

export const COMPANIES_LIST_OPTIONS = [
  {
    value: CompanyMode.company,
    label: "Unternehmen",
  },
  {
    value: CompanyMode.path,
    label: "Pfad",
  },
  {
    value: CompanyMode.utm,
    label: "UTM",
  },
];
