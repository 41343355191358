import { CompanyDetailsParams, apiHandlers } from "@/api";
import { COMPANY_DETAILS } from "@/constants";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

export const useCompanyDetails = (
  params: CompanyDetailsParams,
  enabled: boolean,
): UseQueryResult<any, Error> => {
  return useQuery({
    queryKey: [
      COMPANY_DETAILS,
      params.company_id,
      params.from_date,
      params.to_date,
    ],
    queryFn: () => {
      return apiHandlers.company.details(params).then((res) => {
        return res.data;
      });
    },

    enabled,
    refetchOnWindowFocus: false,
  });
};
