import { DeleteIcon, EditIcon } from "@/assets/icons";
import { IconButton } from "@/components";
import { Segment } from "@/types/segment";
import { MouseEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { getDateRange } from "@/utils";

interface SegmentCardProps {
  segment: Segment;
  onDelete: (id: string) => void;
  handleSetEditableSegment: (segment: Segment) => void;
}

export const SegmentCard = ({
  segment,
  onDelete,
  handleSetEditableSegment,
}: SegmentCardProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isSelected = searchParams.get("segment_id") === segment.id;

  const handleSelectSegment = () => {
    const currentParams = Object.fromEntries(searchParams.entries());

    if (isSelected) {
      const { segment_id, from_date, to_date, ...rest } = currentParams;
      setSearchParams(rest);
      return;
    }

    const segmentPeriod = getDateRange(segment.period);

    setSearchParams({
      ...currentParams,
      segment_id: segment.id,
      from_date: format(segmentPeriod.from as Date, "yyyy-MM-dd"),
      to_date: format(segmentPeriod.to as Date, "yyyy-MM-dd"),
    });
  };

  return (
    <div
      className={`group flex h-[50px] w-full cursor-pointer items-center justify-between gap-3 rounded-md border border-gray-200 p-2 transition-all duration-200 ${
        isSelected ? "bg-blue-100" : "hover:bg-gray-100"
      }`}
      onClick={handleSelectSegment}
    >
      <div className="text-xs font-semibold">{segment.label}</div>

      <div className="flex gap-2 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
        <IconButton
          onClick={(event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            handleSetEditableSegment(segment);
          }}
          icon={<EditIcon />}
        />

        <IconButton
          onClick={() => {
            onDelete(segment.id);
          }}
          icon={<DeleteIcon />}
        />
      </div>
    </div>
  );
};
