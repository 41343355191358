import { PieIcon } from "@/assets/icons";
import { Button } from "@/components/ui/button";
import { Modal } from "flowbite-react";
import { createPortal } from "react-dom";
import { useFormContext, useWatch } from "react-hook-form";

interface SaveSegmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}
export const SaveSegmentModal = ({
  isOpen,
  onClose,
  title,
}: SaveSegmentModalProps) => {
  const { register, control } = useFormContext();

  const label = useWatch({ control, name: "label" });

  return createPortal(
    <Modal
      show={isOpen}
      onClose={onClose}
      size="lg"
      className="z-[100] bg-black/50 backdrop-blur-sm"
    >
      <Modal.Header>
        <div className="flex items-center gap-1">
          <PieIcon />
          <span className="font-semibold leading-6 text-green-900">
            {title}
          </span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <span className="font-bold text-gray-700">Segment Name</span>
        <input
          type="text"
          placeholder="Unterseite X"
          className="form-input w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
          {...register("label")}
        />
      </Modal.Body>
      <Modal.Footer className="flex justify-end">
        <Button variant="outline" onClick={onClose}>
          Abbrechen
        </Button>
        <Button
          type="submit"
          form="segmentsForm"
          disabled={!label.trim().length}
        >
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>,
    document.body,
  );
};
