import { FormValues } from "@/types";
import { Dispatch, SetStateAction } from "react";
import { UseFormReturn, useFieldArray } from "react-hook-form";

export const useFilterCard = ({
  filtersForm,
  groupIndex,
  filterIndex,
  setIds,
  setShowFilterFieldsList,
}: {
  filtersForm: UseFormReturn<FormValues, any, undefined> | undefined;
  groupIndex: number;
  filterIndex: number;
  setIds: Dispatch<
    SetStateAction<{
      groupId: string;
      filterId: string;
    }>
  >;
  setShowFilterFieldsList: (value: SetStateAction<boolean>) => void;
}) => {
  const {
    fields: filters,
    append,
    remove,
  } = useFieldArray({
    control: filtersForm?.control,
    name: `query.${groupIndex}`,
  });

  const { remove: removeGroup } = useFieldArray({
    control: filtersForm?.control,
    name: "query",
  });

  const handleAddNewFilter = () => {
    append({ field: "", condition: "", value: null });
    setIds({
      groupId: `${groupIndex}`,
      filterId: `${+filterIndex + 1}`,
    });
    setShowFilterFieldsList(true);
  };

  const handleRemoveFilter = () => {
    if (filters.length === 1) {
      removeGroup(groupIndex);
      setIds({
        groupId: `${Math.max(+groupIndex - 1, 0)}`,
        filterId: "0",
      });
      return;
    }

    remove(filterIndex);
  };

  return {
    filters,
    handleAddNewFilter,
    handleRemoveFilter,
  };
};
