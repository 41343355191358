import {
  ChartIcon,
  CompanyIcon,
  DocumentIcon,
  MapIcon,
  PostIcon,
  SearchIcon,
} from "@/assets/icons";
import { ReactNode } from "react";

export const SEGMENT_FORM_FIELDS: Array<{
  section: string;
  fields: Array<{
    name: string;
    label: string;
    placeholder: string;
    icon?: ReactNode;
  }>;
}> = [
  {
    section: "Unternehmen",
    fields: [
      {
        name: "company_name",
        label: "Unternehmensname",
        placeholder: "Unternehmensname eingeben",
        icon: <CompanyIcon />,
      },
      {
        name: "postal_code",
        label: "PLZ",
        placeholder: "Postleitzahl eingeben",
        icon: <PostIcon />,
      },
      {
        name: "city",
        label: "Stadt",
        placeholder: "Stadt eingeben",
        icon: <MapIcon />,
      },
      {
        name: "country",
        label: "Land",
        placeholder: "Land eingeben",
        icon: <MapIcon />,
      },
    ],
  },
  {
    section: "Sitzung",
    fields: [
      {
        name: "utm_medium",
        label: "UTM Medium",
        placeholder: "UTM Medium eingeben",
        icon: <SearchIcon />,
      },
      {
        name: "utm_campaign",
        label: "UTM Kampagne",
        placeholder: "UTM Kampagne eingeben",
        icon: <SearchIcon />,
      },
      {
        name: "utm_term",
        label: "UTM Term",
        placeholder: "UTM Term eingeben",
        icon: <SearchIcon />,
      },
      {
        name: "utm_content",
        label: "UTM Content",
        placeholder: "UTM Content eingeben",
        icon: <SearchIcon />,
      },
      {
        name: "utm_campaign_id",
        label: "UTM Kampagnen-ID",
        placeholder: "UTM Kampagnen-ID eingeben",
        icon: <SearchIcon />,
      },
      {
        name: "session_duration",
        label: "Sitzungsdauer",
        placeholder: "Sitzungsdauer eingeben",
        icon: <SearchIcon />,
      },
      {
        name: "session_time",
        label: "Sitzungszeit",
        placeholder: "Sitzungszeit eingeben",
        icon: <SearchIcon />,
      },
    ],
  },
  {
    section: "Benutzerdaten",
    fields: [
      {
        name: "document_location",
        label: "Website Pfad",
        placeholder: "URL Pfad eingeben",
        icon: <DocumentIcon />,
      },
      {
        name: "document_title",
        label: "Website Titel",
        placeholder: "Website Titel eingeben",
        icon: <DocumentIcon />,
      },
      {
        name: "scroll_depth",
        label: "Scrolltiefe",
        placeholder: "Scrolltiefe eingeben",
        icon: <DocumentIcon />,
      },
    ],
  },
  {
    section: "NACE",
    fields: [
      {
        name: "nace_code",
        label: "NACE-Code",
        placeholder: "NACE-Code eingeben",
        icon: <ChartIcon />,
      },
      {
        name: "nace_section",
        label: "NACE-Abschnitt",
        placeholder: "NACE-Abschnitt eingeben",
        icon: <ChartIcon />,
      },
      {
        name: "nace.division",
        label: "NACE-Abteilung",
        placeholder: "NACE-Abteilung eingeben",
        icon: <ChartIcon />,
      },
      {
        name: "nace_activity",
        label: "NACE-Aktivität",
        placeholder: "NACE-Aktivität eingeben",
        icon: <ChartIcon />,
      },
    ],
  },
];
