import { RADIO_OPTIONS } from "@/constants";
import { SetStateAction } from "react";
import { useFormContext } from "react-hook-form";

export const useNewFilterForm = (
  filterKey: string,
  setShowFilterFieldsList: (value: SetStateAction<boolean>) => void,
) => {
  const { control, watch, setValue, unregister } = useFormContext();
  const currentFilter = watch(filterKey);

  const resetFilter = () => {
    setValue(`${filterKey}.field`, "");
    setValue(`${filterKey}.value`, "");
    setValue(`${filterKey}.condition`, RADIO_OPTIONS[0].value);
    setShowFilterFieldsList(true);
  };

  const removeFilter = () => {
    unregister(filterKey);
    setShowFilterFieldsList(false);
  };

  const handleBack = () => {
    if (currentFilter?.field) {
      resetFilter();
      return;
    }

    removeFilter();
  };

  return {
    control,
    currentFilter,
    handleBack,
    setValue,
  };
};
