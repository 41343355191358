import { deleteData, getData, postData, putData } from "./crudHandlers";

import {
  AxiosResponseSuccess,
  CompanyDetailsParams,
  CreateSegmentResponse,
  LoginRequestData,
  SearchCompanyParams,
  SearchCompanyResponseData,
  SearchSegmentParams,
  SegmentListParams,
  SegmentSearchCompany,
  UpdateSegmentProps,
  apiPaths,
} from ".";
import { CreateSegmentData, Segment } from "@/types/segment";
import { CompaniesDetails } from "@/types";

export const apiHandlers = {
  auth: {
    login: (data: LoginRequestData): AxiosResponseSuccess<unknown> => {
      return postData<unknown>(apiPaths.auth.login(), { data });
    },
  },
  company: {
    multiSearch: (
      params: SearchCompanyParams,
    ): AxiosResponseSuccess<SearchCompanyResponseData> => {
      return getData<SearchCompanyResponseData>(
        apiPaths.company.multiSearch(),
        {
          params,
        },
      );
    },
    search: (
      params: SearchCompanyParams,
    ): AxiosResponseSuccess<SearchCompanyResponseData> => {
      return getData<SearchCompanyResponseData>(apiPaths.company.search(), {
        params,
      });
    },
    details: (params: CompanyDetailsParams) => {
      return getData<CompaniesDetails>(apiPaths.company.details(), {
        params,
      });
    },
  },
  segment: {
    getSegments: (
      params: SegmentListParams,
    ): AxiosResponseSuccess<Segment[]> => {
      return getData(apiPaths.segment.getSegments(), {
        params,
      });
    },
    getSegmentById: (segment_id: string): AxiosResponseSuccess<Segment> => {
      return getData(apiPaths.segment.getSegmentById(segment_id));
    },
    searchSegmentCompany: (
      data: SegmentSearchCompany,
    ): AxiosResponseSuccess<SearchCompanyResponseData> => {
      return postData(apiPaths.company.search(), {
        data,
      });
    },
    createSegment: (
      data: CreateSegmentData,
    ): AxiosResponseSuccess<CreateSegmentResponse> => {
      return postData(apiPaths.segment.createSegment(), {
        data,
      });
    },
    updateSegment: ({
      id,
      data,
    }: UpdateSegmentProps): AxiosResponseSuccess<CreateSegmentData> => {
      return putData(apiPaths.segment.updateSegment(id), {
        data,
      });
    },
    deleteSegment: (id: string): AxiosResponseSuccess<string> => {
      return deleteData(apiPaths.segment.deleteSegment(id));
    },
    segmentSearch: (
      segment_id: string,
      params: SearchSegmentParams,
    ): AxiosResponseSuccess<SearchCompanyResponseData> => {
      return getData<SearchCompanyResponseData>(
        apiPaths.segment.segmentSearch(segment_id),
        {
          params,
        },
      );
    },
  },
} as const;
