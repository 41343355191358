import { Outlet } from "react-router-dom";
import clsx from "clsx";
import DateFilterDialog from "./components/date-filter-dialog";
import { useEffect } from "react";
import LogoWhite from "@/assets/ci/webmetic-logo-white.svg";
import LogoBlack from "@/assets/ci/webmetic-logo-black.svg";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { useCompaniesFilterView } from "@/hooks/index.ts";
import { SegmentCard } from "./components/segment-card.tsx";
import { GERMAN_LABELS } from "@/constants/germanLabels.ts";
import { useCompanyFilterContext } from "@/hooks/useCompanyFilterContext.ts";
import { PieIcon } from "@/assets/icons/PieIcon.tsx";
import { IconButton } from "@/components/index.ts";
import { PlusIcon } from "@/assets/icons/PlusIcon.tsx";
import SegmentsDrawer from "./components/segments-drawer/index.tsx";

function CompaniesFilterView() {
  const {
    user,
    periodList,
    params,
    segmentsList,
    dateRange,
    selectedItem,
    setDateRange,
    resetSelectedCompany,
    handleDeleteSegment,
    handleSelectDateRangeOption,
    handleUpdateSearchParams,
  } = useCompaniesFilterView();

  const { setIsOpenFilterDrawer, handleSelectSegmentToEdit } =
    useCompanyFilterContext();

  useEffect(() => {
    document.documentElement.setAttribute("data-route", "dashboard");
    return () => document.documentElement.removeAttribute("data-route");
  }, []);

  return (
    <>
      <div className="hidden xl:flex">
        <div className="flex w-72 grow flex-col overflow-y-auto border-r bg-muted/40">
          <div className="sticky top-0 flex h-16 shrink-0 items-center border-b bg-background px-6">
            <img
              src={LogoBlack}
              className="mr-3 mt-1 h-7 dark:hidden sm:h-9"
              alt="Webmetic Logo"
            />
            <img
              src={LogoWhite}
              className="mr-3 mt-1 hidden h-7 dark:block sm:h-9"
              alt="Webmetic Logo"
            />
          </div>
          {!!user?.website.length && (
            <div className="sticky top-16 border-b bg-background p-2">
              <Select
                value={params.domain}
                onValueChange={resetSelectedCompany}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Domain" />
                </SelectTrigger>
                <SelectContent>
                  {user.website.map((v) => (
                    <SelectItem key={v} value={v}>
                      {v}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}

          {/* Period list */}
          <nav className="flex flex-1 flex-col px-6">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {periodList.map((item) => (
                    <li key={item.label}>
                      <button
                        type="button"
                        onClick={handleSelectDateRangeOption(item)}
                        className={clsx(
                          selectedItem?.label === item.label
                            ? "bg-muted text-primary hover:text-primary"
                            : "text-muted-foreground hover:text-primary",
                          "group flex w-full justify-between gap-x-3 rounded-md px-2 py-1 text-sm font-semibold leading-6 transition-all",
                        )}
                      >
                        <span className="truncate capitalize">
                          {GERMAN_LABELS[item.label] || item.label}
                        </span>
                        <span className="inline-flex min-h-6 min-w-6 shrink-0 items-center justify-center rounded-lg border px-2 py-1 text-xs font-medium text-muted-foreground group-hover:text-primary">
                          {item.companies}
                        </span>
                      </button>
                    </li>
                  ))}

                  <li>
                    <DateFilterDialog
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      onSubmit={handleUpdateSearchParams}
                      trigger={
                        <button
                          type="button"
                          className={clsx(
                            "text-muted-foreground hover:bg-muted hover:text-primary",
                            "group flex w-full flex-col gap-x-3 rounded-md border p-2 text-sm font-semibold leading-6",
                          )}
                        >
                          <span className="capitalize">Zeitraum festlegen</span>
                          <span className="text-start text-[0.625rem] font-medium">
                            Datum manuell auswählen
                          </span>
                        </button>
                      }
                    />
                  </li>
                </ul>
              </li>

              <li>
                <div className="flex flex-col gap-2 pb-2 pt-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1">
                      <PieIcon />
                      <span className="font-semibold leading-6 text-green-900">
                        Segmente
                      </span>
                    </div>

                    <IconButton
                      onClick={() => setIsOpenFilterDrawer(true)}
                      icon={<PlusIcon />}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    {segmentsList?.map((segment) => (
                      <SegmentCard
                        key={segment.id}
                        segment={segment}
                        onDelete={handleDeleteSegment}
                        handleSetEditableSegment={handleSelectSegmentToEdit}
                      />
                    ))}
                  </div>
                </div>

                <ul role="list" className="-mx-2 my-2 space-y-1">
                  <li>
                    <SegmentsDrawer
                      trigger={
                        !segmentsList?.length && (
                          <button
                            type="button"
                            onClick={() => setIsOpenFilterDrawer(true)}
                            className={clsx(
                              "text-muted-foreground hover:text-primary",
                              "group flex w-full flex-col gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                            )}
                          >
                            <span className="btn">
                              Neues Segment hinzufügen.
                            </span>
                          </button>
                        )
                      }
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default CompaniesFilterView;
