import {
  CreateSegmentResponse,
  SearchCompanyResponseData,
  SegmentSearchCompany,
  apiHandlers,
} from "@/api";
import { GenericMutation } from "@/types";
import { CreateSegmentData } from "@/types/segment";
import { useMutation } from "@tanstack/react-query";

type UpdateSegmentVariables = {
  id: string;
  data: CreateSegmentData;
};

interface useSegmentMutationsProps {
  createSegmentMutation: GenericMutation<
    CreateSegmentResponse,
    CreateSegmentData
  >;
  updateSegmentMutation: GenericMutation<
    CreateSegmentData,
    UpdateSegmentVariables
  >;
  deleteSegmentMutation: GenericMutation<string, string>;
  searchSegmentCompany: GenericMutation<
    SearchCompanyResponseData,
    SegmentSearchCompany
  >;
}

export const useSegmentMutations = (): useSegmentMutationsProps => {
  const { mutate: createSegmentMutation } = useMutation({
    mutationFn: apiHandlers.segment.createSegment,
  });
  const { mutate: searchSegmentCompany } = useMutation({
    mutationFn: apiHandlers.segment.searchSegmentCompany,
  });

  const { mutate: updateSegmentMutation } = useMutation({
    mutationFn: apiHandlers.segment.updateSegment,
  });

  const { mutate: deleteSegmentMutation } = useMutation({
    mutationFn: apiHandlers.segment.deleteSegment,
  });

  return {
    createSegmentMutation,
    updateSegmentMutation,
    deleteSegmentMutation,
    searchSegmentCompany,
  };
};
