export function getLeadScoreClass(leadScore: number): string {
  const ranges = [
    { min: 0, max: 10, className: "bg-red-600" },
    { min: 10, max: 25, className: "bg-orange-500" },
    { min: 25, max: 40, className: "bg-orange-400" },
    { min: 40, max: 55, className: "bg-yellow-300" },
    { min: 55, max: 70, className: "bg-green-400" },
    { min: 70, max: 85, className: "bg-green-500" },
    { min: 85, max: 95, className: "bg-green-600" },
    { min: 95, max: 100, className: "bg-green-700" },
  ];

  const match = ranges.find(
    (range) => leadScore > range.min && leadScore <= range.max,
  );
  return match ? match.className : "";
}
