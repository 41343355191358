import { IconProps } from "@/types";

export const XIcon = ({ h = 4, w = 4, color }: IconProps) => (
  <svg
    className={`h-${h} w-${w} text-webmetic_dark-400`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={color ? color : "currentColor"}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18 18 6M6 6l12 12"
    />
  </svg>
);
