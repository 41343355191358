export const RADIO_OPTIONS = [
  { label: "enthält", value: "contains" },
  { label: "enthält nicht", value: "does not contain" },
  { label: "ist", value: "is" },
  { label: "ist nicht", value: "is not" },
  { label: "beginnt mit", value: "starts with" },
  { label: "beginnt nicht mit", value: "does not start with" },
  { label: "endet mit", value: "ends with" },
  { label: "endet nicht mit", value: "does not end with" },
  { label: "ist eines von", value: "is any of" },
  { label: "ist keines von", value: "is not any of" },
  { label: "größer als", value: "greater than" },
  { label: "größer oder gleich", value: "greater than or equal" },
  { label: "kleiner als", value: "less than" },
  { label: "kleiner oder gleich", value: "less than or equal" },
  { label: "regex", value: "regex" },
];