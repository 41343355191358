import { DateTime } from "luxon";

export const getDaysLeftInGerman = (timestamp: string) => {
  const now = DateTime.now();
  const lastSession = DateTime.fromISO(timestamp);
  const diffInDays = Math.ceil(now.diff(lastSession, "days").days);

  if (diffInDays === 1) return "Heute";
  if (diffInDays === 2) return "Gestern";
  if (diffInDays < 7) return `Vor ${diffInDays} Tagen`;
  if (diffInDays < 30) return `Vor ${Math.floor(diffInDays / 7)} Wochen`;
  if (diffInDays < 365) return `Vor ${Math.floor(diffInDays / 30)} Monaten`;
  return `Vor ${Math.floor(diffInDays / 365)} Jahren`;
};
