import { useParams, useSearchParams } from "react-router-dom";

import { CompanyDetailsComponent } from "./company-details-component.tsx";

function CompanyDetails() {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const company_id = params.company_details_id || "";
  const from_date = searchParams.get("from_date") || "-30 days";
  const to_date = searchParams.get("to_date") || "now";

  return (
    <div className="h-[calc(100dvh-4rem)] overflow-scroll">
      <CompanyDetailsComponent
        company_id={company_id}
        from_date={from_date && from_date}
        to_date={to_date && to_date}
      />
    </div>
  );
}

export default CompanyDetails;
