import { MouseEvent, ReactNode } from "react";

interface IconButtonProps {
  icon: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  size?: "small" | "medium" | "large";
  color?:
    | "transparent"
    | "primary"
    | "secondary"
    | "danger"
    | "neutral"
    | "semiTransparent";
  className?: string;
}

const sizeClasses = {
  small: "p-1 text-xs",
  medium: "p-2 text-sm",
  large: "p-3 text-md",
};

const colorClasses = {
  primary: "bg-blue-500 hover:bg-blue-600 text-white",
  secondary: "bg-gray-500 hover:bg-gray-600 text-white",
  danger: "bg-red-500 hover:bg-red-600 text-white",
  neutral:
    "bg-gray-300 hover:bg-gray-400 dark:bg-gray-600 dark:hover:bg-gray-500 text-black dark:text-white",
  transparent:
    "hover:bg-gray-300 dark:hover:bg-gray-700 text-black dark:text-white",
  semiTransparent:
    "hover:bg-gray-300/20 dark:hover:bg-gray-700 text-black dark:text-white",
};

export const IconButton = ({
  icon,
  onClick,
  size = "medium",
  color = "transparent",
  className = "",
}: IconButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className={`inline-flex items-center justify-center rounded-full transition-all duration-300 ${sizeClasses[size]} ${colorClasses[color]} ${className}`}
  >
    {icon}
  </button>
);
