export enum CompanyMode {
  company = "company",
  path = "path",
  utm = "utm",
  segment = "segment",
}

export type Company = {
  company_id: string;
  company_name: string;
  company_url: string;
  company_logo_url: string;
  lead_score: number;
  city: string;
  country: string;
  document_locations: string[];
  utm_parameters: string[];
  industry?: string;
  last_session_timestamp?: string;
};

export interface CompanyFilter {
  groupId: number;
  label: string;
  condition: string;
  query: string;
}

export interface CompaniesFilterDate {
  companies: number;
  date_end: string;
  date_start: string;
  label: string;
}

export interface CompanyFilterGroup {
  id: number;
  filters: CompanyFilter[];
}

export interface QueryField {
  field: string;
  condition: string;
  value: string | number | null;
}

export type QueryGroup = QueryField[];

export type FormValues = {
  label: string;
  query: QueryGroup[];
  domain: string;
  period: CompaniesFilterDate;
};

export interface CompaniesDetailsSessionUserData {
  document_location: string;
  document_title: string;
  referrer: string;
  scroll_depth: number;
  time_spent: number;
  timestamp: string;
  user_events_count: number;
}

export interface CompaniesDetailsSession {
  aid: string;
  client_id: string;
  color_depth: string;
  color_scheme: string;
  connection_type: string;
  cookies_enabled: boolean;
  gci: string;
  gsi: string;
  gsn: string;
  platform: string;
  screen_resolution: string;
  session_duration: number;
  session_id: string;
  session_time: number;
  timestamp: string;
  user_agent: string;
  user_data: CompaniesDetailsSessionUserData[];
  user_language: string;
  utm_medium: string;
  utm_source: string;
  viewport: string;
  weburl: string;
  user_agent_category: string;
  user_agent_os: {
    family: string;
    version: string;
  };
}

export interface CompaniesDetails {
  address: string;
  city: string;
  company_id: string;
  company_logo_url: string;
  company_name: string;
  company_url: string;
  country: string;
  directors: string[];
  email_address: string;
  fax_number: string;
  full_description_de: string;
  full_description_en: string;
  lead_score: number;
  linkedin: string;
  // nace_codes: (2) [{…}, {…}]
  phone_number: string;
  postal_code: string;
  registration_court: string;
  registration_number: string;
  sessions: CompaniesDetailsSession[];
  short_description_de: string;
  short_description_en: string;
  vat_id: string;
}
